@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.footerSection__heading {
  margin: 0 !important;
  padding: 5px 0 10px;
  font-size: 20px !important;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.MuiChip-label {
  font-size: 14px;
}
